import {
    Typography,
    TextField,
    Grid,
    Box,
    InputAdornment,
    Button,
    Switch,
    Slider,
    Stack,
    FormHelperText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControlLabel
} from '@mui/material';

import { useEffect, useState, useRef } from 'react';
import { useAlert } from '@blaumaus/react-alert';

import { HexColorPicker } from 'react-colorful';
import request from 'utils/request';

import * as Yup from 'yup';
import { Formik } from 'formik';

const QuoterAdd = ({ success, open, handleClose }) => {
    const alert = useAlert();

    const [loading, setLoading] = useState(false);

    const handleConfirm = async (values) => {
        console.log(values);
        try {
            setLoading(true);
            const json = await request('/quoter/add', values, {});
            if (json === false) {
                setLoading(false);
                console.log('Unauth access');
                return;
            }

            if (json.r === 1) {
                setLoading(false);
                alert.success('Quoter added.');
                success(json.quoter);
                handleClose();
            }
        } catch (err) {
            console.log(err);
            alert.error('Error adding quoter');
            setLoading(false);
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                name: '',
                type: 'basic',
                clearTrash: 7,
                deleteOpen: 1,
                maxFileSize: 20,
                maxFileParts: 25,
                requireEmail: false
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Name is required'),
                type: Yup.string().required('Type is required'),
                clearTrash: Yup.number().required('Clear Trash is required'),
                deleteOpen: Yup.number().required('Delete Open is required'),
                maxFileSize: Yup.number().required('Max File Size is required'),
                maxFileParts: Yup.number().required('Max File Parts is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    handleConfirm(values);
                    setStatus({ success: false });
                    setSubmitting(false);
                } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
                return (
                    <Dialog open={open} onClose={handleClose}>
                        <form style={{ width: '500px' }} noValidate onSubmit={handleSubmit}>
                            <DialogTitle>Add Quoter</DialogTitle>
                            <DialogContent>
                                <Grid container sx={{ pt: 2 }} spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="status-name"
                                            name="name"
                                            label="Quoter mame"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            value={values.name}
                                            disabled={loading}
                                            onChange={(e) => handleChange(e)}
                                            error={Boolean(touched.name && errors.name)}
                                        />
                                        {touched.name && errors.name && (
                                            <FormHelperText error id="standard-weight-helper-text-name">
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="quoter-type">Type</InputLabel>
                                            <Select
                                                name="type"
                                                labelId="quoter-type"
                                                id="quoter-type"
                                                value={values.type}
                                                onChange={handleChange}
                                                label="Type"
                                            >
                                                <MenuItem value="basic">Basic</MenuItem>
                                                <MenuItem value="3d-printer">3D Printer</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="dense"
                                            id="quoter-clear-trash"
                                            name="clearTrash"
                                            label="Delete trash after days (0 to disable)"
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            min="0"
                                            max="30"
                                            value={values.clearTrash}
                                            disabled={loading}
                                            onChange={(e) => handleChange(e)}
                                            error={Boolean(touched.clearTrash && errors.clearTrash)}
                                        />
                                        {touched.clearTrash && errors.clearTrash && (
                                            <FormHelperText error id="standard-weight-helper-text-clear-trash">
                                                {errors.clearTrash}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="dense"
                                            id="quoter-delete-open"
                                            name="deleteOpen"
                                            label="Delete leads  with status open after days (0 to disable)"
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            min="0"
                                            max="30"
                                            value={values.deleteOpen}
                                            disabled={loading}
                                            onChange={(e) => handleChange(e)}
                                            error={Boolean(touched.deleteOpen && errors.deleteOpen)}
                                        />
                                        {touched.deleteOpen && errors.deleteOpen && (
                                            <FormHelperText error id="standard-weight-helper-text-delete-open">
                                                {errors.deleteOpen}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="dense"
                                            id="quoter-max-file-size"
                                            name="maxFileSize"
                                            label="Max file size (MB)"
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            min="1"
                                            max="100"
                                            value={values.maxFileSize}
                                            disabled={loading}
                                            onChange={(e) => handleChange(e)}
                                            error={Boolean(touched.maxFileSize && errors.maxFileSize)}
                                        />
                                        {touched.maxFileSize && errors.maxFileSize && (
                                            <FormHelperText error id="standard-weight-helper-text-max-file-size">
                                                {errors.maxFileSize}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="dense"
                                            id="quoter-max-file-parts"
                                            name="maxFileParts"
                                            label="Max file parts"
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            min="1"
                                            max="50"
                                            value={values.maxFileParts}
                                            disabled={loading}
                                            onChange={(e) => handleChange(e)}
                                            error={Boolean(touched.maxFileParts && errors.maxFileParts)}
                                        />
                                        {touched.maxFileParts && errors.maxFileParts && (
                                            <FormHelperText error id="standard-weight-helper-text-max-file-parts">
                                                {errors.maxFileParts}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.requireEmail}
                                                    onChange={(e) => handleChange(e)}
                                                    name="requireEmail"
                                                />
                                            }
                                            label="Require email"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="secondary" disabled={loading} onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} onClick={handleSubmit}>
                                    Add
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                );
            }}
        </Formik>
    );
};

export default QuoterAdd;
