import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography,
    ListItem,
    ListItemAvatar,
    ListItemText,
    List,
    Divider,
    ListItemButton
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

import AddIcon from '@mui/icons-material/Add';

// assets
import { useAuth } from 'components/AuthProvider';
import QuoterAdd from 'components/dialog/QuoterAdd';
import QuoterSelect from 'components/dialog/QuoterSelect';

import { useAlert } from '@blaumaus/react-alert';

import { getIcon, getType } from 'utils/utils';

import request from 'utils/request';
import { set } from 'lodash';

// ==============================|| HEADER CONTENT - Quoters ||============================== //

const Quoters = () => {
    const alert = useAlert();
    const { client, setClient } = useAuth();
    const theme = useTheme();

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const onQuoterAdd = (quoter) => {
        setClient({ ...client, quoters: [...client.quoters, quoter] });
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const openAddQuoter = () => {
        setOpen(false);
        setOpenAdd(true);
    };

    const handleQuoterSelect = async (quoter) => {
        try {
            const json = await request('/quoter/set', { quoterId: quoter._id }, {});
            if (json === false) {
                console.log('Unauth access');
                return;
            }

            if (json.r === 1) {
                setClient({ ...client, selectedQuoter: json.quoter });
            }
        } catch (err) {
            console.log(err);
            alert.error('Error setting quoter');
        }
    };

    const iconBackColorOpen = 'grey.300';

    if (!client.selectedQuoter) {
        console.log('not selected');
        return <QuoterSelect />;
    }

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.lighter' }
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar sx={{ width: 32, height: 32 }}>{getIcon(client.selectedQuoter.type)}</Avatar>
                    <Box>
                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                            {client.selectedQuoter.name}
                        </Typography>
                        <Typography variant="caption" component="div" sx={{ marginTop: '-6px' }}>
                            Selected Quoter
                        </Typography>
                    </Box>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: 290,
                                    minWidth: 240,
                                    maxWidth: 290,
                                    [theme.breakpoints.down('md')]: {
                                        maxWidth: 250
                                    }
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard elevation={0} border={false} content={false}>
                                        <List sx={{ width: '100%' }}>
                                            {client.quoters.map((quoter) => {
                                                if (quoter._id == client.selectedQuoter._id) {
                                                    return (
                                                        <ListItem key={quoter._id} sx={{ backgroundColor: 'grey.200' }}>
                                                            <ListItemAvatar>
                                                                <Avatar>{getIcon(quoter.type)}</Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={quoter.name} secondary={getType(quoter.type)} />
                                                        </ListItem>
                                                    );
                                                } else {
                                                    return (
                                                        <ListItem key={quoter._id} disablePadding>
                                                            <ListItemButton onClick={() => handleQuoterSelect(quoter)}>
                                                                <ListItemAvatar>
                                                                    <Avatar>{getIcon(quoter.type)}</Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary={quoter.name} secondary={getType(quoter.type)} />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                                }
                                            })}
                                            <Divider />
                                            <ListItem key="add" disablePadding>
                                                <ListItemButton onClick={() => openAddQuoter()}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <AddIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Add Quoter" />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
            <QuoterAdd success={(quoter) => onQuoterAdd(quoter)} open={openAdd} handleClose={() => setOpenAdd(false)} />
        </Box>
    );
};

export default Quoters;
